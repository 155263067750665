<template>
  <div class="pinboard">
    <img class="img-fluid w-100 header-img" src="./../assets/suedpark.png" />
    <div class="headline">
      <h1>Die <strong>Südliche Neustadt</strong> braucht deine Idee!</h1>
    </div>

    <!-- eslint-disable -->
    <ul id="notes">
      <li v-if="item.note_approved == '1'" v-for="(item, index) of notes" :key="index"
        :style="{ transform: 'scale(' + (1 + (item.note_like / 100 * 2) - (item.note_dislike / 100 * 2)) + ')' }">
        <a class="note-with-image p-0" v-if="item.note_image_path != 0" onclick="event.preventDefault();" href="#"
          @mouseover="hover = true; hoverItem = index;" @mouseleave="hover = false; hoverItem = false">
          <img class="img-fluid w-100" :src="imageUrl + item.note_image_path" />
          <div class="p-4">
            <strong>{{ item.note_text }}</strong>
            <p>{{ item.note_desc }}</p>
            <div class="stickyBox" v-if="hoverItem === index">
              <div class="p-2"><strong>Kommentare:</strong></div>
              <ul class="comment-section">
                <li v-for="(comment, index) in filteredList(item.note_id)" :key="index">
                  <div v-if="comment.comment_approved == '1'" class="comment comment-text">
                    {{ comment.comment_text }}
                  </div>
                </li>
              </ul>

              <b-button block variant="success" v-b-modal.modal-addComment
                @click="newComment.note_id = item.note_id; commentHeadline(item.note_text, index); removeMessage();">
                <b-icon icon="chat"></b-icon> Kommentieren
              </b-button>
            </div>

            <div class="text-right mt-2 likes">
              <button v-if="checkLikes(item.note_id)" @click="item.note_like++; selectNote(item); likeNote();"
                type="button" class="btn btn-primary">
                <b-icon icon="hand-thumbs-up-fill"></b-icon> <span class="badge badge-light">{{ item.note_like }}</span>
              </button>
              <button v-else disabled type="button" class="btn btn-primary">
                <b-icon icon="hand-thumbs-up-fill"></b-icon> <span class="badge badge-light">{{ item.note_like }}</span>
              </button>

              <button v-if="checkLikes(item.note_id)" @click="item.note_dislike++; selectNote(item); dislikeNote();"
                type="button" class="btn btn-primary ml-1">
                <b-icon icon="hand-thumbs-down-fill"></b-icon> <span class="badge badge-light">{{ item.note_dislike
                }}</span>
              </button>
              <button v-else disabled type="button" class="btn btn-primary ml-1">
                <b-icon icon="hand-thumbs-down-fill"></b-icon> <span class="badge badge-light">{{ item.note_dislike
                }}</span>
              </button>

            </div>
          </div>
        </a>

        <a v-else onclick="event.preventDefault();" href="#" @mouseover="hover = true; hoverItem = index;"
          @mouseleave="hover = false; hoverItem = false">
          <strong>{{ item.note_text }}</strong>
          <p>{{ item.note_desc }}</p>

          <div class="stickyBox" v-if="hoverItem === index">
            <div class="p-2"><strong>Kommentare:</strong></div>
            <ul class="comment-section">
              <li v-for="(comment, index) in filteredList(item.note_id)" :key="index">
                <div v-if="comment.comment_approved == '1'" class="comment comment-text">
                  {{ comment.comment_text }}
                </div>
              </li>
            </ul>

            <b-button block variant="success" v-b-modal.modal-addComment
              @click="newComment.note_id = item.note_id; commentHeadline(item.note_text, index); removeMessage();">
              <b-icon icon="chat"></b-icon> Kommentieren
            </b-button>
          </div>

          <div class="text-right mt-2">
            <button v-if="checkLikes(item.note_id)" @click="item.note_like++; selectNote(item); likeNote();" type="button"
              class="btn btn-primary">
              <b-icon icon="hand-thumbs-up-fill"></b-icon> <span class="badge badge-light">{{ item.note_like }}</span>
            </button>
            <button v-else disabled type="button" class="btn btn-primary">
              <b-icon icon="hand-thumbs-up-fill"></b-icon> <span class="badge badge-light">{{ item.note_like }}</span>
            </button>

            <button v-if="checkLikes(item.note_id)" @click="item.note_dislike++; selectNote(item); dislikeNote();"
              type="button" class="btn btn-primary ml-1">
              <b-icon icon="hand-thumbs-down-fill"></b-icon> <span class="badge badge-light">{{ item.note_dislike
              }}</span>
            </button>
            <button v-else disabled type="button" class="btn btn-primary ml-1">
              <b-icon icon="hand-thumbs-down-fill"></b-icon> <span class="badge badge-light">{{ item.note_dislike
              }}</span>
            </button>

          </div>
        </a>

      </li>
    </ul>
    <!-- eslint-disable -->

    <b-modal ref="modal-addComment" id="modal-addComment" :title="newCommentHeadline + ' - kommentieren'" hide-footer>
      <div v-if="message" class="alert alert-success mb-0" role="alert">
        {{ message }}
      </div>
      <div v-else>
        <b-form-group description="maximal 250 Zeichen">
          <b-form-textarea type="textarea" maxlength="250" class="mb-2" v-model="newComment.comment_text"
            placeholder="Kommentartext"></b-form-textarea>
        </b-form-group>
        <b-button variant="success" size="lg" class="mt-3" block @click="createComment()">Jetzt einreichen</b-button>
      </div>
    </b-modal>

    <b-button size="lg" variant="success" @click="removeMessage()" v-b-modal.modal-addNote class="add-note">
      <b-icon icon="plus"></b-icon>
    </b-button>
    <b-button size="lg" variant="info" v-b-modal.modal-infoNote class="info-note">
      <b-icon icon="info"></b-icon>
    </b-button>
    <b-button size="lg" variant="primary" v-b-modal.modal-descNote class="desc-note">
      <b-icon icon="question"></b-icon>
    </b-button>
    <b-button size="lg" variant="primary" v-b-modal.modal-descNote class="lang-note">
      <a href="https://pinnwand--neustadt-de.translate.goog/?_x_tr_sch=http&_x_tr_sl=auto&_x_tr_tl=de&_x_tr_hl=de&_x_tr_pto=wapp"
        target="_blank"><b-icon icon="translate"></b-icon></a>
    </b-button>

    <b-modal ok-only id="modal-infoNote" title="Info">
      <p>Die Freiraumgalerie – Kollektiv für Raumentwicklung wurde von der Stadt Halle beauftragt, Ideen für die Südliche
        Neustadt zu entwickeln und erste Projekte umzusetzen.<br /><br />Um viele verschiedene Menschen an dem Prozess zu
        beteiligen,
        wollen wir auf dieser Homepage Deine Meinung wissen.<br /><br />Teile mit uns Deine Idee, wie der öffentliche Raum
        in der Südlichen Neustadt schöner, aufregender und gemeinschaftlicher gestaltet werden kann!</p>
      <div class="text-center"><a href="https://www.freiraumgalerie.com/" target="_blank"><img class="img-fluid"
            src="./../assets/logo.png" /></a></div>
    </b-modal>
    <b-modal ok-only id="modal-descNote" title="Anleitung">
      <p><span class="pink-color"><strong>DEINE Idee:</strong></span> Schreib uns, welche spannende Aktion oder
        Veränderung die Südliche Neustadt braucht!<br /><br />
        <span class="pink-color"><strong>ALLE Ideen:</strong></span> Schau Dir an, welche Vorschläge andere Menschen
        gemacht haben und bewerte diese! Klick hierfür auf einen der Zettel und verteile Daumen hoch oder
        runter.<br /><br />
        Nachdem Du etwas abgeschickt hast, wird Dein Beitrag inhaltlich geprüft und spätestens nach 24 Stunden
        veröffentlicht.<br /><br />
        OK, los geht’s!
      </p>
    </b-modal>
    <div>
      <b-modal ref="modal-addNote" id="modal-addNote" title="Ein neue Idee einreichen" hide-footer>
        <div v-if="message" class="alert alert-success" role="alert">
          {{ message }}
        </div>
        <div v-else>
          <div v-if="imageMessage" class="alert alert-danger" role="alert">
            {{ imageMessage }}
          </div>
          <b-form-group description="maximal 250 Zeichen">
            <b-form-input maxlength="250" class="mb-2" v-model="newNote.note_text"
              placeholder="Überschrift"></b-form-input>
          </b-form-group>
          <b-form-group description="maximal 250 Zeichen">
            <b-form-textarea maxlength="250" v-model="newNote.note_desc" placeholder="Beschreibung"></b-form-textarea>
          </b-form-group>
          <b-form-group
            description="Hier können Sie optional ein Bild hochladen. Bitte beachten Sie, dass das Bild nicht größer als 5MB sein darf.">
            <b-form-file v-model="newNote.note_image" placeholder="Wähle ein Bild aus" accept="image/*"
              @change="checkImageSize"></b-form-file>
          </b-form-group>
          <b-button variant="success" size="lg" class="mt-3" block @click="createNote()">Jetzt einreichen</b-button>
        </div>
      </b-modal>
    </div>

  </div>
</template>

<script>

global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

//let url = '//localhost:8888/pinboard/';
let url = 'http://pinnwand-neustadt.de/';
//let url = 'https://pinboard.jonasschock.de/';
export default {
  name: 'HomeView',
  data() {
    return {
      imageUrl: url,
      newNote: {
        note_approved: '0',
        note_like: 0,
        note_dislike: 0,
        note_text: '',
        note_desc: '',
        note_image: null,
      },
      newComment: {
        comment_text: '',
        comment_approved: '0',
        note_id: 0,
      },
      newCommentHeadline: '',
      newCommentIndex: '',
      message: '',
      imageMessage: '',
      hover: false,
      hoverItem: '',
      notes: [],
      comments: []
    }
  },
  mounted: function () {
    this.getNotes();
    this.getComments();
    document.body.classList.add('notes-bg');
  },
  methods: {
    checkImageSize(event) {
      const selectedFile = event.target.files[0]; // Get the selected file
      const maxSizeInBytes = 5 * 1024 * 1024; // 1MB (adjust as needed)

      if (selectedFile && selectedFile.size > maxSizeInBytes) {
        // File size exceeds the allowed limit
        this.imageMessage = 'Die Dateigröße des Bildes ist zu groß. Bitte passen Sie die Größe an sodass es weniger als 5 MB hat.';
        // Optionally, clear the input field
        event.target.value = '';
      } else {
        // File size is within the allowed limit; you can proceed with the upload
        this.imageMessage = '';
        // ...
      }
    },
    handleImageUpload(event) {
      // Handle image file selection
      this.selectedImage = event.target.files[0];
    },
    getNotes: function () {
      var vm = this;
      this.$axios.get(url + 'notes.php?action=read')
        .then(function (response) {
          console.log(response);

          if (response.data.error) {
            console.log('Axios Get Problem');
          } else {
            console.log(response.data.notes);
            vm.notes = response.data.notes;
          }
        })
    },
    getComments: function () {
      var vm = this;
      this.$axios.get(url + 'comments.php?action=read')
        .then(function (response) {
          console.log(response);

          if (response.data.error) {
            console.log('Axios Get Problem');
          } else {
            console.log(response.data.comments);
            vm.comments = response.data.comments;
          }
        })
    },
    // createNote: function () {
    //   var vm = this;
    //   var formData = vm.toFormData(vm.newNote);
    //   this.$axios.post(url+'notes.php?action=create', formData)
    //     .then(function (response) {
    //     console.log(response);

    //     vm.newNote.note_text = '',
    //     vm.newNote.note_desc = '',
    //     vm.newNote.note_like = 0,
    //     vm.newNote.note_dislike = 0,
    //     vm.newNote.note_approved = '0'

    //     if (response.data.error) {
    //       vm.message = response.data.message;
    //     } else {
    //       vm.message = 'Vielen Dank für deinen Beitrag. Dieser wird inhaltlich geprüft und spätestens nach 24 Stunden veröffentlicht.';
    //       vm.getNotes();
    //     }
    //   });
    // },
    createNote: function () {
      var vm = this;
      var formData = vm.toFormData(vm.newNote); // Convert newNote data to FormData

      // Check if there's an image selected
      if (vm.newNote.note_image) {
        // Append the selected image file to the formData
        formData.append('note_image', vm.newNote.note_image);
      }

      // Use Axios to send the formData to the 'notes.php?action=create' endpoint
      this.$axios.post(url + 'notes.php?action=create', formData)
        .then(function (response) {
          console.log(response);

          vm.newNote.note_text = '';
          vm.newNote.note_desc = '';
          vm.newNote.note_like = 0;
          vm.newNote.note_dislike = 0;
          vm.newNote.note_approved = '0';
          vm.newNote.note_image = null; // Clear the image after submission

          if (response.data.error) {
            vm.message = response.data.message;
          } else {
            vm.message = 'Vielen Dank für deinen Beitrag. Dieser wird inhaltlich geprüft und spätestens nach 24 Stunden veröffentlicht.';
            vm.getNotes();
          }
        });
    },

    createComment: function () {
      var vm = this;
      var formData = vm.toFormData(vm.newComment);
      this.$axios.post(url + 'comments.php?action=create', formData)
        .then(function (response) {
          console.log(response);

          vm.newComment.comment_text = '',
            vm.newComment.comment_approved = '0'
          vm.newComment.note_id = 0

          if (response.data.error) {
            vm.message = response.data.message;
          } else {
            vm.message = 'Vielen Dank für deinen Kommentar. Dieser wird inhaltlich geprüft und spätestens nach 24 Stunden veröffentlicht.';
            vm.getComments();
          }
        });
    },
    likeNote: function () {
      localStorage.setItem('like ' + this.clickedNote.note_id, this.clickedNote.note_id)
      var vm = this;
      var formData = vm.toFormData(vm.clickedNote);
      this.$axios.post(url + 'notes.php?action=update', formData)
        .then(function (response) {
          console.log(response);
          vm.clickedNote = {};

          if (response.data.error) {
            vm.errorMessage = response.data.message;
          } else {
            vm.successMessage = response.data.message;
            vm.getNotes();
          }
        });
    },
    dislikeNote: function () {
      localStorage.setItem('like ' + this.clickedNote.note_id, this.clickedNote.note_id)
      var vm = this;
      var formData = vm.toFormData(vm.clickedNote);
      this.$axios.post(url + 'notes.php?action=update', formData)
        .then(function (response) {
          console.log(response);
          vm.clickedNote = {};

          if (response.data.error) {
            vm.errorMessage = response.data.message;
          } else {
            vm.successMessage = response.data.message;
            vm.getNotes();
          }
        });
    },
    toFormData: function (obj) {
      var form_data = new FormData();
      for (var key in obj) {
        form_data.append(key, obj[key]);
      }
      return form_data;
    },

    commentHeadline(text, index) {
      this.newCommentHeadline = text;
      this.newCommentIndex = index;
    },
    incrementLike(index) {
      this.notes[index].like++;
      this.saveJson();
    },
    addComment() {
      this.notes[this.newCommentIndex].comments.push(this.newComment);
      this.newComment = {};
      this.newCommentHeadline = '';
      this.newCommentIndex = '';
      this.saveJson();
    },
    removeMessage: function () {
      this.message = ''
    },
    selectNote(item) {
      this.clickedNote = item;
    },
    filteredList(note) {
      var vm = this;
      return this.comments.filter(function (comment) {
        return comment.note_id === note; // Use equality check instead of includes
      });
    },
    checkLikes(id) {
      return id != localStorage.getItem('like ' + id);
    },
    checkDisLikes(id) {
      return id != localStorage.getItem('dislike ' + id);
    },
  }
}
</script>

<style>
html {
  background: url("./../assets/coark_bg.jpeg");

}

body {
  background: transparent;
}

.pinboard {
  min-height: 100vh;
  padding-bottom: 200px;
}

.stickyBox {
  background: rgba(255, 255, 255, 0.7);
  border-radius: 15px;
}

/* .note-with-image .stickyBox {
  position: absolute;
  bottom: -75px;
  width: 100%;
}
.note-with-image .likes {
  position: absolute;
  right: 5px;
  bottom: 5px;
  z-index: 1;
} */

.comment-section {
  margin: 0;
  padding: 0;
}

.comment-section li {
  margin: 0;
  padding: 0;
}

.comment-section li {
  font-size: 12px;
  border-radius: 10px;
  margin-bottom: 10px;
  width: 70%;
}

.comment {
  padding: 10px;
}

.comment-section li:nth-child(2n+1) {
  background: #efefef;
  margin-left: 20%;
  margin-right: 10%;
  text-align: left;

}

.comment-section li:nth-child(2n+2) {
  background: #ccc;
  margin-right: 20%;
  margin-left: 10%;
}

.btn {
  text-decoration: none;
  border: 0;
  border-radius: 3px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .26);
  display: inline-block;
  text-align: center;
  transition: 0.1s all ease;
}

.btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, .4);
}

.add-note {
  position: fixed;
  bottom: 15px;
  right: 15px;
  font-size: 50px;
  background-color: #cc2b7b !important;
}

.pink-color {
  color: #cc2b7b !important
}

.info-note {
  position: fixed;
  bottom: 15px;
  font-size: 30px;
  right: 125px;
  background-color: #4193bc !important;
}

.blue-color {
  color: #4193bc !important
}

.desc-note {
  position: fixed;
  bottom: 15px;
  font-size: 30px;
  right: 205px;
  background-color: #a6c350 !important;
}

.lang-note {
  position: fixed;
  bottom: 15px;
  font-size: 30px;
  right: 285px;
  background-color: #333 !important;
}

.lang-note a {
  color: #fff;
}

.btn-approve {
  position: fixed;
  top: 5px;
  right: 5px;
}

.green-color {
  color: #a6c350 !important
}

.modal-header {
  color: #ffffff;
  background: rgb(204, 43, 123);
  background: linear-gradient(90deg, rgba(204, 43, 123, 1) 0%, rgba(65, 147, 188, 1) 50%, rgba(166, 195, 80, 1) 100%);
}

.btn-success {
  background-color: #cc2b7b;
}

.btn-success:hover,
.btn-success:focus .btn-success:active {
  background-color: #cc2b7b !important;
  border-color: #cc2b7b !important;
}

.btn-primary {
  background-color: #4193bc;
}

.btn-primary:hover,
.btn-primary:focus .btn-primary:active {
  background-color: #4193bc !important;
  border-color: #4193bc !important;
}

ul,
li {
  list-style: none;
}

ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
}

ul li:hover {
  z-index: 1;
}

ul li a {
  text-decoration: none;
  color: #000;
  display: block;
  width: 15em;
  padding: 1em;
  padding-top: 2em;
  padding-bottom: .5em;
  box-shadow: 5px 5px 7px rgba(33, 33, 33, .7);
  transition: transform .15s linear;
  transform: rotate(-3deg);
}

ul li a:before {
  width: 25px;
  height: 28px;
  background: url("./../assets/pin.png");
  content: "";
  display: block;
  position: absolute;
  left: 6.5em;
  top: -5px;
}

ul li:nth-child(5n) a {
  transform: rotate(3deg);
  position: relative;
  top: -10px;
}

ul li:nth-child(2n) a {
  background: #ccf;
  transform: rotate(1.5deg);
}

ul li::nth-child(3n) a {
  background: #b7ddfe;
  transform: rotate(-5deg);
}

ul li:nth-child(5n) a {
  background: #fbcfe1;
  transform: rotate(-2deg);
}

ul li:nth-child(11n) a {
  background: #cfc;
  transform: rotate(-1deg);
}

ul li:nth-child(13n) a {
  background: #ffc;
  transform: rotate(4deg);
}

ul li:nth-child(17n) a {
  background: #ccf;
  transform: rotate(-.5deg);
}

ul li:nth-child(2n+1) a {
  background: #b7ddfe;
  transform: rotate(-3.5deg);
}

ul li:nth-child(3n+2) a {
  background: #ffc;
  transform: rotate(2.5deg);
}

ul li:nth-child(5n+3) a {
  background: #cfc;
  transform: rotate(-1.5deg);
}

ul li:nth-child(11n+5) a {
  background: #fbcfe1;
  transform: rotate(-2deg);
}

ul li:nth-child(13n+11) a {
  background: #ffc;
  transform: rotate(1.5deg);
}

ul li:nth-child(17n+13) a {
  background: #ccf;
  transform: rotate(2deg);
}

ul li {
  margin: 1em;
}

ul li a:hover {
  box-shadow: 10px 10px 7px rgba(0, 0, 0, .7);
  transform: scale(1.1);
  position: relative;
  z-index: 5;
  text-decoration: none;
  color: #333;
}

/* @media screen and (min-width:822px) {
  ul li a.note-with-image:hover{
    transform: scale(2);

  }
  ul li a.note-with-image:hover .likes {
    transform: scale(0.5);
    transform-origin: bottom right;
  }
} */

ul li a.note-with-image img {}

ul li a:hover:before {
  display: none;
}

h1 {
  font-size: 44px;
  color: #333;
  background: #ffffff;
  padding: 10px 20px;
  display: inline;
  font-weight: 300;
}

h1 strong {
  font-weight: 600;
}

.headline {
  position: absolute;
  top: 100px;
  text-align: center;
  width: 100%;
}

.img-copyright {
  background: rgba(255, 255, 255, .7);
  position: fixed;
  bottom: 5px;
  left: 5px;
  font-size: 10px;
  padding: 3px;
  color: #333;
}

.img-copyright a {
  color: #333;
  text-decoration: underline;
}

#notes {
  margin: -30px 0 0 0;

}

@media screen and (max-width:821px) {
  ul li {
    transform: scale(1) !important;
  }

  .headline {
    top: 120px;
  }

  h1 {
    font-size: 24px;
    line-height: 34px;
    display: block;
    padding: 5px;
  }

  .header-img {
    height: 270px;
    object-fit: cover;
  }
}</style>
